import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider } from 'antd';

import FieldsGroup from '@atoms/form/fields-group';
import ContentWrapper from '@atoms/content-wrapper';
import RenderPreviewItem from '@molecules/formater/TextPreviewFormater';

import PhoneChange from './PhoneChange/PhoneChange';
import EmailChange from './EmailChange/EmailChange';
import AddressChange from './AddressChange/AddressChange';

import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logout: false,
      loading: false,
    };
  }

  toggleModal(visible = !this.state.visible) {
    this.setState({ visible: visible });

    if (!visible) {
      this.props.fetchAllPersonalData();
    }
  }

  render() {
    const { t, userType, personalData } = this.props;
    const {
      title,
      first_name,
      middle_name,
      last_name,
      date_of_birth,
      country,
      city,
      post_code,
      address1,
      address2,
      new_actual_address,
      phone,
      country_phone_code,
      email,
      customer_id,
      risk_level_changed_by_profile,
    } = personalData;

    return (
      <Row gutter={50}>
        <Col>
          <ContentWrapper>
            <FieldsGroup header={t('fieldsGroup.personalInformation.title')}>
              <Row gutter={20} className={'text-preview'}>
                {RenderPreviewItem(
                  t('field.full_name.label'),
                  `${title ? `${title}.` : ''} ${first_name} ${middle_name || ''} ${last_name}`,
                  22
                )}

                {!!date_of_birth && (
                  <>
                    {RenderPreviewItem(
                      t('field.date_of_birth.label'),
                      defaults.date.local(date_of_birth, defaults.date.defaultDate),
                      22
                    )}
                  </>
                )}

                <Divider />

                {!!address1 && (
                  <>
                    <Col md={20}>
                      <h3>{t('fieldsGroup.actualAddress.title')}</h3>
                    </Col>
                    <Col md={4} className={'align-right'}>
                      <AddressChange
                        data={{ address1, country, city, post_code, address2, new_actual_address }}
                        userType={userType}
                        fetchAllPersonalData={this.props.fetchAllPersonalData}
                      />
                    </Col>
                    {RenderPreviewItem(
                      t('field.country'),
                      defaults.location.getCountryByKey(country).title,
                      24
                    )}
                    {RenderPreviewItem(t('field.city'), city)}
                    {RenderPreviewItem(t('field.post_code'), post_code)}
                    {RenderPreviewItem(t('field.address1'), address1)}
                    {RenderPreviewItem(t('field.address2'), address2)}
                    <Divider />
                  </>
                )}

                {RenderPreviewItem(
                  t('field.phone.label'),
                  phone && country_phone_code ? `+${country_phone_code} ${phone}` : '-',
                  20
                )}
                <Col md={4} className={'align-right'}>
                  <PhoneChange userType={userType} />
                </Col>

                {RenderPreviewItem(t('field.email.label'), email, 20)}
                <Col md={4} className={'align-right'}>
                  <EmailChange userType={userType} />
                </Col>

                {userType !== 'aemi' && (
                  <>
                    <Divider />
                    {RenderPreviewItem(t('field.customer_id.label'), customer_id, 22)}
                    {risk_level_changed_by_profile && (
                      <>
                        <Divider />
                        {RenderPreviewItem(
                          t('field.risk_level_changed_by_profile.label'),
                          risk_level_changed_by_profile,
                          22
                        )}
                      </>
                    )}
                  </>
                )}
              </Row>
            </FieldsGroup>
          </ContentWrapper>
        </Col>
      </Row>
    );
  }
}

export default tConsume(PersonalInformation);

PersonalInformation.propTypes = {
  userType: PropTypes.string.isRequired,
  personalData: PropTypes.object,
  fetchAllPersonalData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
