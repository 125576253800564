// redux-toolkit packages;
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';

// persist package;
import storage from 'redux-persist/lib/storage/session';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

// reducers
import aclReducer from '@features/aclSlice';
import casesReducer from '@features/casesSlice';
import customerReducer from '@features/customerSlice';
import currencyReducer from '@features/currencySlice';
import menuReducer from '@features/menuSlice';
import personalReducer from '@features/personalSlice';
import sessionReducer from '@features/sessionSlice';
import userTokenReducer from '@features/userTokenSlice';
import userLevelsReducer from '@features/userLevelsSlice';

const reducers = combineReducers({
  acl: aclReducer,
  cases: casesReducer,
  currencies: currencyReducer,
  customer: customerReducer,
  menu: menuReducer,
  personal: personalReducer,
  session: sessionReducer,
  userToken: userTokenReducer,
  userLevels: userLevelsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'acl',
    'case',
    'customer',
    'currencies',
    'menu',
    'personal',
    'session',
    'userToken',
    'userLevels',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
export const persistor = persistStore(store);
