export function getQueryParams(search = null, data = {}) {
  if (!search) {
    search = new URL(window.location.href).search;
  }

  const params = new URLSearchParams(search);
  const queryParams = {};

  for (const [key, value] of params.entries()) {
    if (data && data?.mode === 'multiple') {
      queryParams[key] = value.split(',');
    } else if (key.endsWith('[]')) {
      const arrayKey = key.slice(0, -2);
      if (!queryParams[arrayKey]) {
        queryParams[arrayKey] = [];
      }
      queryParams[arrayKey].push(value);
    } else {
      queryParams[key] = value;
    }
  }
  return queryParams;
}
