import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import isObject from 'lodash.isobject';

import { Form, Select, Spin } from 'antd';
import { SelectTypes } from '@atoms/form/select-item';
import setLabel from '@atoms/form/funcs/setLabel';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';
import { isString } from 'mathjs';

const { Option, OptGroup } = Select;

class FormSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: Math.random().toString(),
      label: '',
      extra: '',
      size: defaults.antd.input.size,
      fetching: false,
      defaultValue: '',
      search: '',
    };

    this._mounted = false;

    this.handleChange = this.handleChange.bind(this);
    this.itemsRequest = debounce(this.itemsRequest.bind(this), 500);
    this.setKeys = this.setKeys.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    const { t, item, isAddon, onSearch } = this.props;

    const _item = isAddon ? item[isAddon] : item;

    const label = setLabel(_item, t);

    const initState = {
      extra: t(`field.${_item.name}.extra`, false),
      label: label,
      placeholder: !!_item.placeholder,
    };

    this.setState(
      {
        ..._item,
        ...initState,
        defaultValue: _item.value ? _item.value : '',
      },
      () => {
        if (
          onSearch &&
          typeof _item.value !== 'undefined' &&
          !(_item.temporaryKeys && item._static)
        ) {
          this.itemsRequest(_item.value);
          return;
        }

        this.setKeys();
      }
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    const { onSearch, item, isAddon, _static, disabled } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const _prev = prevProps.isAddon ? prevProps.item[isAddon] : prevProps.item;

    if (onSearch && typeof _item.value !== 'undefined' && _item.value !== _prev.value) {
      this.itemsRequest(_item.value);
    }

    if (!_static && disabled !== prevProps.disabled) {
      this.handleChange('');
    }
  }

  setKeys() {
    const { t, item, isAddon } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const { type, keys } = _item;

    let _keys = [],
      _options = {};

    if (keys && typeof keys === 'string') {
      _keys = t(`keys.${keys}`).slice();
    }

    if (type && type in SelectTypes) {
      const _module = SelectTypes[type](_item);

      if (Array.isArray(_module)) {
        _keys = _module;
      }

      if (!Array.isArray(_module)) {
        _keys = _module.keys(t);

        if ('formater' in _module && typeof _module.formater === 'function') {
          _options.formater = _module.formater;
        }
      }
    }

    this.setState({ ..._options }, () => {
      if (_keys.length) {
        this.updateKeys(_keys);
      }
    });
  }

  renderKeys() {
    const { _static, item, isAddon } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const { value, keys } = _item;

    if (!keys) {
      return null;
    }

    if (!_static) {
      if (keys.length > 0 && isObject(keys[0]) && 'keys' in keys[0] && 'label' in keys[0]) {
        return keys
          .sort((item1, item2) => {
            const order1 = item1.order ? item1.order : 0;
            const order2 = item2.order ? item2.order : 0;
            return order2 - order1;
          })
          .map((item, i) => {
            return (
              <OptGroup label={item.label} key={`optgroup-${i}`}>
                {this.renderOptions(item.keys)}
              </OptGroup>
            );
          });
      }

      return this.renderOptions(keys);
    }

    const _value = keys.filter((item) => item.value === value)[0];

    return [
      <Option value={_value ? _value.value : ''} key={1}>
        {_value ? (typeof _value.title === 'string' ? _value.title : _value.value) : ''}
      </Option>,
    ];
  }

  renderOptions(keys) {
    const { uid } = this.state;

    return keys.map((element, index) => {
      let _title = element;
      let _value = element;

      if (typeof element === 'object' && 'title' in element) {
        _title = element['title'];
        _value = element['value'];
      }

      return (
        <Option key={`${uid}-${index}`} value={_value} disabled={element?.disabled}>
          {_title}
        </Option>
      );
    });
  }

  /**
   *
   * Calls parrent change method
   * @param {*} event
   *
   */
  handleChange(value) {
    const { _static, item, isAddon } = this.props;

    if (_static) {
      return;
    }

    const newValue = isAddon
      ? {
          name: item.name,
          help: '',
          status: '',
          [isAddon]: {
            value: value,
          },
        }
      : {
          name: item.name,
          value: value,
          help: '',
          status: '',
        };

    this.props.onChange(newValue);
  }

  updateKeys(keys) {
    const { item, isAddon } = this.props;

    const newValue = isAddon
      ? {
          name: item.name,
          [isAddon]: {
            keys,
          },
        }
      : {
          name: item.name,
          keys,
        };

    this.props.onChange(newValue);
  }

  /**
   *
   * @param {string} input
   * @param {*} option
   * @returns {bool} of result
   *
   */
  itemSearch(input, option) {
    if (option.props.children) {
      const filterTitle = isString(option.props.children)
        ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        : false;
      const filterValue = isString(option.props.value)
        ? option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        : false;

      return filterValue || filterTitle;
    }

    return null;
  }

  /**
   *
   * Directs the request to the desired method
   * @param {string} value
   *
   */
  itemsRequest(value) {
    const { onSearch } = this.props;

    if (onSearch && this._mounted) {
      this.setState({ fetching: true }, () => {
        // this.handleChange(value);
        onSearch(value, this);
      });
    }
  }

  onSelect(value) {
    const { item, isAddon, onSelect } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const { keys } = _item;

    if (onSelect) {
      this.setState({ search: '' }, () => {
        onSelect(keys.find((key) => key.value === value).data);
      });
    }
  }

  onFocus(value) {
    const { item, isAddon } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const { keys } = _item;

    if (!keys || keys.length == 0) {
      this.itemsRequest(value ? value : '');
    }
  }

  render() {
    const { t, _static, disabled, loading, item, isAddon, onSearch } = this.props;
    const _item = isAddon ? item[isAddon] : item;
    const { status, help, value, disableSearch, mode, required } = _item;
    const { name, label, extra, fetching, size, formater } = this.state;

    let _value = value ? value : '';
    const classes = [];

    let inputValue = '';

    if (_value) {
      classes.push('notEmpty');

      inputValue = formater ? formater(_value) : _value;
    }

    if (required) {
      classes.push('isRequired');
    }

    if (_static) {
      classes.push('staticSelect');
    }

    const _width = isAddon ? (_item.width ? _item.width : 120) : '100%';
    const notFound = fetching ? <Spin size="small" /> : t('api.searchNotFound');

    const select = (
      <>
        <Select
          mode={mode}
          allowClear={true}
          style={{ width: _width }}
          showSearch={!disableSearch}
          showArrow={onSearch === undefined}
          defaultValue={_value}
          value={_value}
          size={size}
          filterOption={!disableSearch && this.itemSearch}
          onSearch={this.itemsRequest}
          onChange={this.handleChange}
          onSelect={this.onSelect}
          onFocus={this.onFocus}
          disabled={_static || disabled || loading}
          autocomplete="off"
          notFoundContent={notFound}
          placeholder={label}
          className={classes.join(' ')}
        >
          {this.renderKeys()}
        </Select>
        <input name={name || ''} value={inputValue} disabled={!_value} hidden readOnly />
      </>
    );

    if (!isAddon) {
      return (
        <Form.Item extra={extra} validateStatus={status} help={help} required={required}>
          {select}
        </Form.Item>
      );
    }

    return select;
  }
}

export default tConsume(FormSelect);

FormSelect.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    keys: PropTypes.Array,
    temporaryKeys: PropTypes.Array,
    required: PropTypes.bool,
    disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    placeholder: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  _static: PropTypes.bool,
  isAddon: PropTypes.string,
  t: PropTypes.func.isRequired,
};

FormSelect.defaultProps = {
  disabled: false,
  loading: false,
  isAddon: '',
};
