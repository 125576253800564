import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip, Icon } from 'antd';
import moment from 'moment-timezone';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

const MessageNavigator = ({ route, userType, t }) => {
  const unreadMessages = useSelector((state) => state.cases.unread);
  const { menuConfig } = useSelector((state) => state.menu);
  const messageRoute =
    userType !== 'aemi'
      ? menuConfig.additional && menuConfig.additional.find((item) => item.name === 'messages')
      : menuConfig.top && menuConfig.top.find((item) => item.name === 'cases');

  return (
    <div className={'dashboard-wrapper-menu unread-messages'} style={{ borderRight: 'none' }}>
      {unreadMessages.data.length > 0 ? (
        <div className={'unread-messages-list'}>
          <h3 className={'unread-messages-title'}>{t('component.messageNavigator.title')}</h3>
          {unreadMessages.data.map((request) => (
            <NavLink
              to={defaults.routing.getRoute(
                `${messageRoute.path}/${request.id}${userType === 'aemi' ? '/communication' : ''}`,
                route
              )}
              key={`unread_message_${request.id}`}
              className={'unread-messages-item'}
            >
              {request.new_messages !== 0 && request.is_pinned && <Icon type={'pushpin'} />}
              <div className={'unread-messages-item-content'}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {request.new_messages === 0 && request.is_pinned && <Icon type={'pushpin'} />}
                  <p className={'unread-messages-item-subject'}>{request.subject}</p>
                </div>
                {request.new_messages !== 0 && request.is_pinned && (
                  <>
                    <p className={'unread-messages-item-text'}>
                      {userType === 'aemi' ? request.client_name : request.last_message.body}
                    </p>
                    <Tooltip
                      title={defaults.date.local(request.updated_at, defaults.date.localDateTime24)}
                    >
                      <p className={'unread-messages-item-time'}>
                        {moment(request.updated_at).fromNow()}
                      </p>
                    </Tooltip>
                  </>
                )}
                {!request.is_pinned && (
                  <>
                    <p className={'unread-messages-item-text'}>
                      {userType === 'aemi' ? request.client_name : request.last_message.body}
                    </p>
                    <Tooltip
                      title={defaults.date.local(request.updated_at, defaults.date.localDateTime24)}
                    >
                      <p className={'unread-messages-item-time'}>
                        {moment(request.updated_at).fromNow()}
                      </p>
                    </Tooltip>
                  </>
                )}
              </div>
              {request.new_messages !== 0 && request.is_pinned && (
                <div className={'unread-messages-item-counter'}>{request.new_messages}</div>
              )}
              {!request.is_pinned && (
                <div className={'unread-messages-item-counter'}>{request.new_messages}</div>
              )}
            </NavLink>
          ))}
        </div>
      ) : (
        <h3 className={'unread-messages-title'}>{t('component.messageNavigator.empty')}</h3>
      )}

      {userType !== 'aemi' && (
        <div className={'unread-messages-viewAll'}>
          <NavLink
            to={defaults.routing.getRoute(messageRoute.path, route)}
            className={'unread-messages-item'}
          >
            <Icon type={'message'} />
            <span>{t('component.messageNavigator.viewAll')}</span>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default tConsume(MessageNavigator);

MessageNavigator.propTypes = {
  route: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
