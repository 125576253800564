import defaults from 'DEFAULTS';

export default {
  default: {
    name: 'Dashboard',
  },
  top: [
    {
      name: 'customers',
      path: 'customers',
      icon: 'shop',
      active: true,
      subItems: [
        {
          name: 'company',
          path: 'customers/company',
          permissions: ['customers.view'],
          active: defaults.variables.account_types.includes('company'),
          icon: 'cluster',
        },
        {
          name: 'individual',
          path: 'customers/individual',
          permissions: ['customers.view'],
          active: defaults.variables.account_types.includes('individual'),
          icon: 'user',
        },
      ],
    },
    {
      name: 'reports',
      path: 'reports',
      icon: 'filter',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'popups',
      path: 'popups',
      icon: 'alert',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'aml',
      path: 'aml',
      icon: 'border-verticle',
      active: true,
      permissions: [
        'aml.AML.determine',
        'aml.CRO.determine',
        'aml.MLRO.determine',
        'aml.SPECTATOR.determine',
      ],
      subItems: [
        {
          name: 'payout',
          path: 'aml',
          icon: 'swap',
          active: true,
          permissions: [
            'aml.AML.determine',
            'aml.CRO.determine',
            'aml.MLRO.determine',
            'aml.SPECTATOR.determine',
          ],
        },
        {
          name: 'postcontrol',
          path: 'post-control',
          icon: 'border-verticle',
          active: true,
          permissions: ['post_control.access'],
          subItems: [
            {
              name: 'payin',
              path: 'post-control/transaction',
              icon: 'swap',
              active: true,
              permissions: ['post_control.access'],
            },
            {
              name: 'approved',
              path: 'post-control/approved',
              icon: 'file-done',
              active: true,
              permissions: ['post_control.access'],
            },
            {
              name: 'review',
              path: 'post-control/on-review',
              icon: 'file-search',
              active: true,
              permissions: ['post_control.access'],
            },
          ],
        },
      ],
    },
    {
      name: 'co_workspace',
      path: 'co',
      icon: 'swap',
      active: true,
      permissions: ['client-manager.determine'],
      subItems: [
        {
          name: 'co_approval',
          path: 'co/approval',
          icon: 'swap',
          active: true,
          permissions: ['client-manager.determine'],
        },
        {
          name: 'co_post_control',
          path: 'co/post-control',
          icon: 'file-protect',
          active: true,
          permissions: ['client-manager.determine'],
        },
      ],
    },
    {
      name: 'auditor_workspace',
      path: 'transactional-documents',
      icon: 'file',
      active: true,
      permissions: ['transactional_documents.access'],
      subItems: [
        {
          name: 'transactions',
          path: 'transactional-documents/transactions',
          icon: 'swap',
          active: true,
          permissions: ['transactional_documents.access'],
        },
        {
          name: 'archive',
          path: 'transactional-documents/archive',
          icon: 'file-protect',
          active: true,
          permissions: ['transactional_documents.access'],
        },
      ],
    },
    {
      name: 'entities',
      path: 'entities',
      icon: 'appstore',
      active: true,
      permissions: ['bank_institution.show', 'transactions.view', 'customer-groups.view'],
      subItems: [
        {
          name: 'banks',
          path: 'entities/bank',
          icon: 'bank',
          active: true,
          permissions: ['bank_institution.show'],
        },
        {
          name: 'payment_code',
          path: 'entities/payment-code',
          icon: 'barcode',
          active: true,
          permissions: ['transactions.view'],
        },
        {
          name: 'clients_groups',
          path: 'entities/clients-groups',
          icon: 'team',
          active: true,
          permissions: ['customer-groups.view'],
        },
      ],
    },
    {
      name: 'markets',
      path: 'markets',
      icon: 'flag',
      active: true,
      permissions: ['markets.view', 'market-groups.view', 'markets.customers.assign'],
      subItems: [
        {
          name: 'markets_list',
          path: 'markets/list',
          icon: 'unordered-list',
          active: true,
          permissions: ['markets.view'],
        },
        {
          name: 'markets_divisions',
          path: 'markets/divisions',
          icon: 'usergroup-add',
          active: true,
          permissions: ['market-groups.view'],
        },
      ],
    },
    {
      name: 'treasury',
      path: 'treasury',
      icon: 'audit',
      active: true,
      permissions: [
        'treasury.determine',
        'head_of_treasury.determine',
        'spectator_treasury.determine',
        'treasury_archive.view',
        'treasury.payment_orders.view',
        'treasury.card_indexes.view',
      ],
      subItems: [
        {
          name: 'transactions',
          path: 'treasury/transactions',
          icon: 'audit',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
          ],
          active: true,
        },
        {
          name: 'confirmations',
          path: 'treasury/confirmations',
          icon: 'file-protect',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
          ],
          active: true,
        },
        {
          name: 'archive',
          path: 'treasury/archive',
          icon: 'save',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
            'treasury_archive.view',
          ],
          active: true,
        },
        {
          name: 'card_indexes',
          path: 'card-indexes',
          icon: 'audit',
          permissions: ['treasury.card_indexes.view'],
          active: true,
        },
        {
          name: 'payment_orders',
          path: 'orders',
          icon: 'contacts',
          active: true,
          permissions: ['treasury.payment_orders.view'],
          subItems: [
            {
              name: 'orders',
              path: 'orders/created',
              icon: 'folder-open',
              active: true,
              permissions: ['treasury.payment_orders.view'],
            },
            {
              name: 'orders_confirmed',
              path: 'orders/confirmed',
              icon: 'file-protect',
              active: true,
              permissions: ['treasury.payment_orders.view'],
            },
            {
              name: 'orders_archived',
              path: 'orders/updated',
              icon: 'save',
              active: true,
              permissions: ['treasury.payment_orders.view'],
            },
          ],
        },
      ],
    },
    {
      name: 'foreign_exchanges',
      path: 'exchanges',
      icon: 'sliders',
      active: true,
      permissions: ['fx.order.view'],
      subItems: [
        {
          name: 'exchange_orders',
          path: 'exchanges/orders',
          icon: 'profile',
          active: true,
        },
        {
          name: 'exchange_statistics',
          path: 'exchanges/statistics',
          icon: 'bar-chart',
          active: true,
        },
      ],
    },
    {
      name: 'cases',
      path: 'cases',
      icon: 'issues-close',
      active: true,
      permissions: ['cases.view'],
    },
    {
      name: 'transactions',
      path: 'transactions',
      icon: 'swap',
      active: true,
      permissions: ['transactions.view'],
      subItems: [
        {
          name: 'transactions_list',
          path: 'transactions/list',
          active: true,
          icon: 'unordered-list',
        },
        {
          name: 'unclarified_funds',
          path: 'transactions/unclarified-funds',
          active: true,
          icon: 'file-unknown',
          permissions: ['unclarified-funds.show'],
        },
      ],
    },
    {
      name: 'wallets',
      path: 'wallets',
      icon: 'wallet',
      active: !defaults.variables.external_payments,
      subItems: [
        {
          name: 'accounts',
          path: 'wallets/accounts',
          permissions: ['wallets.view'],
          active: false,
          icon: 'bank',
        },
        {
          name: 'vaults',
          path: 'wallets/vaults',
          permissions: ['wallets.view'],
          active: !defaults.variables.external_payments,
          icon: 'credit-card',
        },
      ],
    },
    {
      name: 'pricing_plans',
      path: 'pricing',
      icon: 'euro',
      active: true,
      permissions: ['pricing_plans.view'],
      subItems: [
        {
          name: 'pricing_list',
          path: 'pricing/plans',
          active: true,
          icon: 'unordered-list',
          permissions: ['pricing_plans.view'],
        },
        {
          name: 'promotions',
          path: 'pricing/promotions',
          permissions: ['tariff_plan_vacation.view'],
          active: true,
          icon: 'pause-circle',
        },
      ],
      // subItems: [
      //   {
      //     name: 'active_plans',
      //     path: 'pricing/active',
      //     active: true,
      //     icon: 'play-circle',
      //   },
      //   {
      //     name: 'inactive_plans',
      //     path: 'pricing/inactive',
      //     active: true,
      //     icon: 'pause-circle',
      //   },
      //   {
      //     name: 'management',
      //     path: 'pricing/management',
      //     active: true,
      //     icon: 'solution',
      //     subItems: [
      //       {
      //         name: 'individual',
      //         path: 'pricing/management/individual',
      //         active: defaults.variables.account_types.includes('individual'),
      //         icon: 'user',
      //       },
      //       {
      //         name: 'company',
      //         path: 'pricing/management/company',
      //         active: defaults.variables.account_types.includes('company'),
      //         icon: 'cluster',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      name: 'statistics',
      path: 'statistics',
      icon: 'line-chart',
      active: true,
      subItems: [
        {
          name: 'vendor_usage',
          path: 'statistics/vendor-usage',
          active: true,
          permissions: ['statistics.view'],
        },
      ],
    },
    {
      name: 'acl',
      path: 'control',
      icon: 'team',
      active: true,
      subItems: [
        {
          name: 'team',
          path: 'control/team',
          active: true,
          permissions: ['acl.team.users.view'],
        },
        {
          name: 'roles',
          path: 'control/roles',
          active: true,
          permissions: ['acl.team.roles.view'],
        },
        {
          name: 'permissions',
          path: 'control/permissions',
          active: true,
          permissions: ['acl.team.roles.view'],
        },
      ],
    },
    {
      name: 'configurations',
      path: 'configurations',
      icon: 'setting',
      active: true,
      permissions: ['configurations.view'],
    },
  ],
  bottom: [
    {
      name: 'personal_profile',
      icon: 'user',
      active: true,
      path: 'profile',
    },
    {
      name: 'logout',
      exit: true,
      icon: 'logout',
      active: true,
    },
  ],
};
