import registrationRoutes from './routes';

/**
 *
 * @param {*} context
 * @param {*} obj
 * @returns {*}
 *
 */
export default (response, route, location, userType) => {
  const obj = response.data;
  const base = 'verification';
  const result = {
    status: 'profile',
    redirect: route,
    currentRoute: 'profile',
    nextStep: 'operational',
    finished: false,
    declined: false,
  };
  const routes = registrationRoutes[userType];

  const currentStepRoute = `${route}/${base}`;
  const currentStep = getCurrentStep(routes, obj);

  const currentPath = location ? getCurrentPath(location) : false;
  const currentRoute = currentPath ? getCurrentRoute(routes, currentPath) : false;
  const nextStep = getNextStep(routes, obj, currentRoute ? currentRoute.route : currentStep.route);
  const finished = allIsFinish(routes, obj);
  result.declined = obj.onb_status.toUpperCase() === 'DECLINED';

  if (!finished) {
    result.redirect = location ? false : `${route}/${base}/${currentStep.route}`;
    result.currentRoute = currentRoute ? currentRoute.route : currentStep.route;
    result.status = currentStep.route;
    result.nextStep = nextStep.route;
    result.finished = false;
  }

  if (!finished && location && result.status !== result.currentRoute) {
    let falseRoute = false;

    if (location.pathname.indexOf(currentStepRoute) < 0) {
      falseRoute = true;
    }

    if (!currentRoute || currentRoute.order > currentStep.order) {
      falseRoute = true;
    }

    if (falseRoute) {
      result.redirect = currentStep.route;
    }
  }

  if (finished) {
    result.status = currentStep.route;
    result.redirect = false;
    result.currentRoute = currentStep.route;
    result.nextStep = currentStep.route;
    result.finished = true;
  }

  return result;
};

const filterActiveRoutes = (routes, obj) =>
  routes.filter((item) => {
    if (item.enabled && obj.hasOwnProperty(item.enabled)) {
      return obj[item.enabled];
    }

    return true;
  });

const allIsFinish = (routes, obj) => {
  const activeRoutes = filterActiveRoutes(routes, obj).filter(
    (item) => !['finish', 'accepted'].includes(obj[item.step])
  );

  return !activeRoutes.length;
};

const getCurrentPath = (location) => {
  let path = location.pathname.lastIndexOf('/');
  return location.pathname.substring(path + 1);
};

const getCurrentStep = (routes, obj) => {
  const result = filterActiveRoutes(routes, obj)
    .filter((item) => !['finish'].includes(obj[item.step]))
    .sort((a, b) => a.order - b.order)[0];

  if (result) {
    return result;
  }

  return routes.sort((a, b) => b.order - a.order)[0];
};

const getNextStep = (routes, obj, currentRoute) => {
  const result = filterActiveRoutes(routes, obj)
    .filter((item) => !['finish'].includes(obj[item.step]))
    .sort((a, b) => a.order - b.order);

  const routeIndex = result.findIndex((item) => item.route === currentRoute);
  const next = result[routeIndex + 1];
  if (next) {
    return next;
  }

  return routes.sort((a, b) => b.order - a.order)[0];
};

const getCurrentRoute = (routes, route) => {
  return routes.filter((item) => item.route === route)[0];
};
