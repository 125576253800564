import defaults from 'DEFAULTS';

export default {
  default: {
    name: 'Dashboard',
  },
  top: [
    {
      name: 'Welcome! You have signed in successfully.',
      path: '',
      icon: 'home',
    },
    {
      name: 'overview',
      path: 'overview',
      active: true,
      icon: 'unordered-list',
    },
    {
      name: 'wallets',
      path: 'wallets',
      icon: 'wallet',
      active: !defaults.variables.external_payments,
      subItems: [
        {
          name: 'accounts',
          path: 'wallets/accounts',
          active: false,
          icon: 'bank',
        },
        {
          name: 'vaults',
          path: 'wallets/vaults',
          active: !defaults.variables.external_payments,
          icon: 'credit-card',
        },
      ],
    },
    {
      name: 'transactions',
      path: 'transactions',
      active: true,
      icon: 'swap',
      subItems: [
        {
          name: 'list_transactions',
          path: 'transactions/list',
          active: true,
          icon: 'unordered-list',
        },
        {
          name: 'draft_transactions',
          path: 'transactions/drafts',
          active: true,
          icon: 'form',
        },
      ],
    },
    {
      name: 'exchanges',
      path: 'exchanges',
      icon: 'sliders',
      active: true,
      permissions: ['fx.view'],
      subItems: [
        {
          name: 'xe',
          path: 'exchanges/xe',
          active: true,
          icon: 'sliders',
          permissions: ['fx.xe.view'],
        },
        {
          name: 'interbank',
          path: 'exchanges/interbank',
          active: true,
          icon: 'sliders',
          permissions: ['fx.interbank.view'],
        },
      ],
    },
    {
      name: 'beneficiaries',
      path: 'counterparties',
      active: defaults.variables.external_payments,
      icon: 'contacts',
    },
    {
      name: 'cards',
      path: 'cards',
      icon: 'credit-card',
    },
    {
      name: 'analytics',
      path: 'analytics',
      icon: 'line-chart',
    },
    {
      name: 'price_plan',
      path: 'price-plan',
      icon: 'tags',
    },
    {
      name: 'help',
      path: 'help',
    },
    {
      name: 'pricing_plans',
      path: 'pricing',
      icon: 'euro',
      active: true,
    },
    {
      name: 'notifications',
      path: 'notifications',
      icon: 'notification',
      active: true,
    },
    {
      name: 'payment_orders',
      path: 'payment-orders',
      active: true,
      icon: 'contacts',
      subItems: [
        {
          name: 'corrections_order',
          path: 'payment-orders/corrections',
          active: true,
          icon: 'edit',
        },
        {
          name: 'cancel_order',
          path: 'payment-orders/cancel',
          active: true,
          icon: 'rollback',
        },
        {
          name: 'details_order',
          path: 'payment-orders/details',
          active: true,
          icon: 'folder-open',
        },
      ],
    },
  ],
  bottom: [
    {
      name: 'personal_profile',
      icon: 'user',
      active: true,
      path: 'profile',
    },
    {
      name: 'logout',
      exit: true,
      icon: 'logout',
      active: true,
    },
  ],
  additional: [
    {
      name: 'messages',
      icon: 'message',
      active: true,
      path: 'requests',
    },
  ],
};
