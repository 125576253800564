import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import Spinner from '@atoms/spinner';
import { changePersonalDataField } from '@features/personalSlice';
import notification from '@helps/notification';
import { AddPhoneForm, TfaPhoneForm } from './index';

import api from 'API';
import { responseError } from 'FIELDS';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';
import { Sms } from 'IMAGE';
import defaults from 'DEFAULTS';
import store from '../../../../store';

const TfaPhone = ({
  type,
  userType,
  email,
  password,
  successCallback,
  errorCallback,
  t,
  phone,
}) => {
  const userTypePhone = store.getState().userToken.tokenType;
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const personalData = useSelector((state) => state.personal);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  // counter
  const [counter, setCounter] = useState(null);
  const [endTimerTimestamp, setEndTimerTimestamp] = useState(null);
  let timerInterval;
  const { localStorageKey, tfaTimer } = defaults;

  const dispatch = useDispatch();

  useEffect(async () => {
    if (personalData.data.phone_tfa_using && personalData.data.tfa_phone) {
      await sendCode(null, true);
    } else if (phone) {
      await sendCode(null, true);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let timerInterval;
    if (endTimerTimestamp) {
      timerInterval = setInterval(() => {
        const secondsLeft = Math.ceil((endTimerTimestamp - Date.now()) / 1000);

        if (secondsLeft > 0) {
          setCounter(secondsLeft);
        } else {
          resetTimer();
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [setCounter, endTimerTimestamp]);

  const startTimer = (endTimestamp) => {
    if (loading) setLoading(false);
    const secondsLeft = Math.ceil((endTimestamp - Date.now()) / 1000);

    if (secondsLeft > 0) {
      setEndTimerTimestamp(endTimestamp);
      setCounter(secondsLeft);
    } else {
      resetTimer();
    }
  };

  const checkTimer = () => {
    let blockSendSms = false;
    const endTimerLS = localStorage.getItem(localStorageKey.tfaTimerEnd);

    if (endTimerLS && endTimerLS > Date.now()) {
      blockSendSms = true;
      startTimer(endTimerLS);
    } else {
      localStorage.removeItem(localStorageKey.tfaTimerEnd);
    }

    return blockSendSms;
  };

  const resetTimer = () => {
    clearInterval(timerInterval);
    setCounter(null);
    setEndTimerTimestamp(null);
    localStorage.removeItem(localStorageKey.tfaTimerEnd);
  };

  const sendCode = async (event, init = false) => {
    if (checkTimer()) return;

    const checkData = { email, password };
    let CONFIG;
    if (personalData.data.phone_tfa_access && !personalData.data.tfa_phone) {
      const data = { country_phone_code: countryCode, phone: phoneNumber };
      console.log(data);
      CONFIG = api.tfa.resendPhone(userTypePhone).AXIOS('POST');
    } else {
      CONFIG =
        type === 'check'
          ? api.tfa.phoneAuthCode(userType).AXIOS('POST', checkData)
          : api.tfa.phoneCode().AXIOS('POST');
    }

    try {
      setLoading(true);
      const result = await axios(CONFIG);

      if (!init) notification(result.data.message, 'success');

      const endTimestamp = Date.now() + tfaTimer * 1000;
      localStorage.setItem(localStorageKey.tfaTimerEnd, JSON.stringify(endTimestamp));
      startTimer(endTimestamp);
    } catch (error) {
      responseError(null, error);
    } finally {
      setLoading(false);
    }
  };

  const checkCode = async ({ tfa_code }) => {
    const data = {
      tfa_code: tfa_code,
      email,
      password,
    };
    if (personalData.data.phone_tfa_access && !personalData.data.tfa_phone) {
      return await axios(api.tfa.verifySms(userTypePhone).AXIOS('PUT', { code: tfa_code }));
    } else {
      return await axios(api.tfa.checkPhoneAuthTfa(userType).AXIOS('POST', data));
    }
  };

  const bindMethod = async ({ tfa_code }) => {
    const data = { tfa_code };
    const title = t('component.tfa.tfa_phone_title');
    let result;
    if (personalData.data.phone_tfa_access && !personalData.data.tfa_phone) {
      result = await axios(api.tfa.verifySms(userTypePhone).AXIOS('PUT', { code: tfa_code }));
    } else {
      result = await axios(api.tfa.bindPhoneTfa().AXIOS('POST', data));
    }
    if (personalData.data.phone_tfa_access && !personalData.data.tfa_phone) {
      dispatch(changePersonalDataField({ tfa_phone: 1 }));
    }
    if (personalData.data.phone_tfa_access && !personalData.data.tfa_phone) {
      notification(result.data.message, 'success');
    } else {
      notification(Strings.Compile(t('component.tfa.method_added'), [title]), 'success');
    }

    return result;
  };

  const addMobilePhone = async (country_phone_code, phone) => {
    setCountryCode(country_phone_code);
    setPhoneNumber(phone);
    const data = { country_phone_code, phone };
    const title = t('component.tfa.tfa_phone_title');

    const result = await axios(api.tfa.addPhone(userTypePhone).AXIOS('POST', data));

    dispatch(changePersonalDataField(result.data));
    notification(Strings.Compile(result.data.message, [title]), 'success');
    setVisibleModal(true);

    return result;
  };

  const handleSubmit = async ({ tfa_code }) => {
    if (type === 'get') {
      return successCallback({
        tfa_code: tfa_code,
        tfa_method: 'phone',
      });
    }

    try {
      setActionLoading(true);
      const result =
        type === 'check' ? await checkCode({ tfa_code }) : await bindMethod({ tfa_code });
      localStorage.removeItem(localStorageKey.tfaTimerEnd);
      await successCallback?.(result);
    } catch (error) {
      notification(error?.response?.data?.error, 'error');
      await errorCallback?.(error);
    } finally {
      setActionLoading(false);
    }
  };

  const handleSubmitAddPhone = async (data) => {
    try {
      setActionLoading(true);
      await addMobilePhone(data.data.country_phone_code, data.data.phone);
    } catch (error) {
      responseError(null, error);
      await errorCallback?.(error);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div className={'phone_container'}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Sms width={'100%'} height={150} style={{ margin: '18px 0' }} />

          {personalData.data.phone_tfa_access && !personalData.data.tfa_phone && !visibleModal ? (
            <AddPhoneForm loading={actionLoading} onConfirm={handleSubmitAddPhone} />
          ) : (
            <TfaPhoneForm loading={actionLoading} onConfirm={handleSubmit} />
          )}
          {visibleModal ||
            (phone && (
              <p className={'textDescription'}>
                {Strings.Compile(
                  t(`component.tfa.${counter ? 'code_resent_in' : 'code_may_be_resent'}`),
                  [counter]
                )}
                <Button disabled={!!counter} size={'small'} type={'link'} onClick={sendCode}>
                  {t('component.tfa.phone_resend')}
                </Button>
              </p>
            ))}
          {!personalData.data.phone_tfa_access && personalData.data.tfa_phone && !visibleModal ? (
            <p className={'textDescription'}>
              {Strings.Compile(
                t(`component.tfa.${counter ? 'code_resent_in' : 'code_may_be_resent'}`),
                [counter]
              )}
              <Button disabled={!!counter} size={'small'} type={'link'} onClick={sendCode}>
                {t('component.tfa.phone_resend')}
              </Button>
            </p>
          ) : null}
          {}
        </>
      )}
    </div>
  );
};

export default tConsume(TfaPhone);

TfaPhone.propTypes = {
  type: PropTypes.oneOf(['bind', 'get', 'check']),
  userType: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  email: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  password: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  phone: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
