import currencyByKey from './currency-by-key';

/**
 *
 * @param {number} amount
 * @param {string} currency
 * @param {array} currencyList
 * @returns {string} formatted amount with formatted currency and symbol
 *
 */
export default (amount = 0, currency = 'EUR', currencyList = []) => {
  if (isNaN(parseFloat(amount))) {
    return '';
  }

  function checkFractalDigits(amount) {
    const isInteger = Number.isInteger(amount);
    const strParts = amount.toString().split('.');

    if (!isInteger && (strParts.length === 1 || (strParts.length > 1 && strParts[1].length > 2))) {
      throw new RangeError('Digits after decimal point is more than 2 or number has exponent');
    }

    return isInteger ? amount : parseFloat(amount);
  }

  try {
    const parsedAmount = checkFractalDigits(amount);

    const formatted = new Intl.NumberFormat('en-GB', {
      currencyDisplay: 'narrowSymbol',
      style: 'currency',
      currency: currency,
    }).format(parsedAmount);

    if (formatted.includes('⃀') || formatted.includes(currency)) {
      return `${currencyByKey(currency, currencyList)} ${new Intl.NumberFormat('en-GB').format(
        parsedAmount
      )}`;
    }

    return formatted;
  } catch (e) {
    if (e.name === 'RangeError') {
      try {
        const formattedAmount = new Intl.NumberFormat('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 7,
        }).format(parseFloat(amount.toString()));

        return `${currencyByKey(currency, currencyList)} ${formattedAmount}`;
      } catch (e) {
        throw e;
      }
    }
  }
};
