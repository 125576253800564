import React, { Component } from 'react';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import Spinner from '@atoms/spinner';
import FieldsControl from './FieldsControl';
import SectionHeader from '../../molecules/section-header';
import ExportTable from './ExportTable';
import { getQueryParams } from '@helps/getQueryParams';

export default class CreateTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      fieldsList: [],
      active_fields: this.getActiveFields(),
      page: 1,
    };

    this.updateDebounced = debounce(this.update.bind(this), 200);

    this.handleFields = this.handleFields.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    if (this.props.columnResize) {
      window.addEventListener('resize', this.updateDebounced);
    }
    const paramsObject = getQueryParams();
    if (this.props.pagination) {
      this.setState({ page: +paramsObject.page || 1 });
    }
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.update();
    }
  }

  getActiveFields = () => {
    const paramsObject = getQueryParams();

    return paramsObject.active_fields
      ? paramsObject.active_fields.split(',').map((item) => (item === 'false' ? false : null))
      : [];
  };

  componentWillUnmount() {
    this._mounted = false;

    window.removeEventListener('resize', this.updateDebounced);
  }

  update() {
    if (this._mounted) {
      this.updateColumns();
      this.updateFieldsList();
    }
  }

  updateColumns() {
    const { columns } = this.props;
    const { active_fields } = this.state;

    let workList = this.getWorkListByProps(columns);

    const columnsTitle = columns.map((item) => item.title);
    workList = workList
      .sort((a, b) => columnsTitle.indexOf(a.title) - columnsTitle.indexOf(b.title))
      .filter((item, index) => {
        if (active_fields.length && active_fields[index] !== null) {
          return active_fields[index];
        }

        return item.hasOwnProperty('show') ? item.show : !this.isNeedToHideColumn(item);
      });

    this.setState({ columns: workList });
  }

  updateFieldsList() {
    const { columns } = this.props;
    const { active_fields } = this.state;

    let workList = this.getWorkListByProps(columns);

    const columnsTitle = columns.map((item) => item.title);
    workList = workList
      .map((item) => {
        return {
          title: item.title,
          dataIndex: item.dataIndex,
          value: item.hasOwnProperty('show') ? item.show : !this.isNeedToHideColumn(item),
        };
      })
      .sort((a, b) => columnsTitle.indexOf(a.title) - columnsTitle.indexOf(b.title));

    this.setState({ fieldsList: workList });
    if (!active_fields.length) {
      this.changeQueryParams('active_fields', '');
      this.setState({
        active_fields: workList.map(() => {
          return null;
        }),
      });
    }
  }

  getWorkListByProps(columns) {
    const { byContainerWidthAndPriority } = this.props;
    const byContainerWidthAndPriorityValid =
      byContainerWidthAndPriority && this.validatePriorityAndWidth(columns);

    let workList = columns;

    if (byContainerWidthAndPriorityValid) {
      workList = this.getColumnsByPriority([...columns]);
    }

    workList = this.filterColumnsByCondition(workList);

    if (byContainerWidthAndPriorityValid) {
      workList = this.setShowPropByWidth(workList);
    }

    return workList;
  }

  // check if all items of table has property 'priority' and 'width'
  validatePriorityAndWidth(columns) {
    return columns.every((item) => item.hasOwnProperty('priority') && item.hasOwnProperty('width'));
  }

  // sort all items of table by 'priority' score
  getColumnsByPriority(columns) {
    return columns.sort((a, b) => {
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
    });
  }

  // filter all items of table by item's condition
  filterColumnsByCondition(columns) {
    return columns.filter(({ condition }) =>
      typeof condition !== 'undefined' ? !!condition : true
    );
  }

  setShowPropByWidth(columns) {
    let availableWidth = window.innerWidth;
    const container = document.getElementsByClassName('ant-table-wrapper')[0];

    if (container) {
      availableWidth = container.offsetWidth;
    }

    return columns.map((item) => {
      if (availableWidth === null || availableWidth <= 0) {
        return {
          ...item,
          show: false,
        };
      }

      availableWidth -= item.width;

      return {
        ...item,
        show: availableWidth > 0,
      };
    });
  }

  compareFieldsState() {
    const { fieldsList, active_fields } = this.state;

    return fieldsList.map((item, index) => {
      let value = {};

      if (active_fields[index] !== null) {
        value = { value: active_fields[index] };
      }
      return { ...item, ...value };
    });
  }

  isNeedToHideColumn(item) {
    return !!((item.matchMedia && this.matchMedia(item.matchMedia)) || item.hidden);
  }

  matchMedia(width) {
    return window.matchMedia(`(max-width: ${width}px)`).matches;
  }

  handleFields(index) {
    const { fieldsList, active_fields } = this.state;
    const _active_fields = active_fields.slice();
    _active_fields[index] =
      active_fields[index] !== null ? !active_fields[index] : !fieldsList[index].value;
    this.changeQueryParams('active_fields', _active_fields);
    this.setState({ active_fields: _active_fields }, () => {
      this.updateColumns();
    });
  }

  handlePageChange = (page) => {
    this.changeQueryParams('page', page);
    this.setState({ page }, () => {
      this.update();
    });
  };

  changeQueryParams = (name, value) => {
    if (!value) {
      return;
    }
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set(name, value);
    url.search = params.toString();
    window.history.pushState({}, '', url);
  };

  loadingSpin() {
    const { loading } = this.props;

    if (loading === undefined) {
      return false;
    }

    return {
      spinning: loading,
      indicator: <Spinner />,
    };
  }

  render() {
    const { fieldsControl, pagination, hideOnSinglePage, exportConfig } = this.props;
    const { columns, page } = this.state;
    let _pagination = false;

    if (pagination) {
      _pagination = {
        ...pagination,
        hideOnSinglePage: pagination.hideOnSinglePage || hideOnSinglePage,
        showTotal: (total, range) => <span>{`${range[0]}-${range[1]} of ${total} items`}</span>,
        onChange: this.handlePageChange,
        current: page,
      };
    }

    return (
      <>
        {(fieldsControl || exportConfig) && (
          <SectionHeader>
            {fieldsControl && (
              <SectionHeader.Item type="button">
                <FieldsControl
                  fields={this.compareFieldsState()}
                  handleFields={this.handleFields}
                />
              </SectionHeader.Item>
            )}
            {exportConfig && (
              <SectionHeader.Item type="button">
                <ExportTable exportConfig={exportConfig} />
              </SectionHeader.Item>
            )}
          </SectionHeader>
        )}
        <Table
          {...this.props}
          columns={columns}
          loading={this.loadingSpin()}
          pagination={_pagination}
        />
      </>
    );
  }
}

CreateTable.propTypes = {
  columnResize: PropTypes.bool,
  loading: PropTypes.bool,
  fieldsControl: PropTypes.bool,
  exportConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hideOnSinglePage: PropTypes.bool,
  byContainerWidthAndPriority: PropTypes.bool,
};

CreateTable.defaultProps = {
  columnResize: false,
  fieldsControl: true,
  exportConfig: false,
  pagination: false,
  hideOnSinglePage: true,
  byContainerWidthAndPriority: false,
};
