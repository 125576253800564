import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from 'API';
import { withNotificationForError } from './helper';

export const fetchAllUserLevels = createAsyncThunk(
  'currencies/fetchAllUserLevels',
  withNotificationForError(async (payload, { getState }) => {
    const userType = getState().userToken.tokenType;

    const response = await axios(api.customers.userLevelClient(userType).AXIOS('GET'));
    return response.data.data;
  })
);

const initialState = {
  loading: false,
  list: [],
  error: null,
};

const userLevelsSlice = createSlice({
  name: 'userLevels',
  initialState: initialState,
  reducers: {
    clearUserLevels() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserLevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllUserLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.list = action.payload;
      })
      .addCase(fetchAllUserLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { clearUserLevels } = userLevelsSlice.actions;
export default userLevelsSlice.reducer;
